<template>
	<div>
		<web-header></web-header>
		<div class="content main">
			<div class="contentOne">
				<img src="../../assets/index/success.png" alt="">
				<div>
					<p>您已付款成功！</p>
					<p>请务必开票！</p>
					<!-- <p><span>￥</span> {{list.cost}}</p> -->
				</div>
			</div>

			<div class="contentThree">
				<div @click="toOrderView">开具发票</div>
				<!-- <div @click="goHome">返回首页</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '../../components/webHeader.vue';
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader
		},
		data() {
			return {
				data: {},
				list: {}
			}
		},
		created() {
			this.list = this.$route.query.data
			this.data = this.$router.query
		},
		computed: {
			...mapState(['menuList'])
		},
		methods: {
			toOrderView() {
				this.$router.push({
					path: '/personalCenter',
					query: {
						index: 6,
						infoIndex: 6
					}
				})
			},
			// 返回首页
			goHome() {
				if (this.menuList[0].content == 8) {
					window.location.href = this.menuList[0].jumpUrl
				} else {
					this.$router.push({
						path: '/',
						query: {
							index: 1
						}
					})
				}
			}
		}

	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: 0 auto;
	}

	.content {
		height: 696px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin-top: 16px;
		padding: 70px;
	}

	.contentOne {
		display: flex;
		justify-content: center;
		margin-top: 50px;
		line-height: 50px;

		img {
			height: 80px;
			width: 80px;
			display: block;
			margin-right: 16px;
		}

		p {
			font-size: 24px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}

	.contentTwo {
		margin-top: 40px;
		margin-left: 100px;

		p {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #666666;
			margin-bottom: 20px;
		}
	}

	.contentThree {
		margin-top: 80px;
		margin-left: 100px;
		display: flex;
		justify-content: center;

		div {
			width: 201px;
			height: 66px;
			background: #E51E0B;
			font-size: 24px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 66px;
			margin-right: 20px;
			cursor: pointer;
		}

		div:nth-child(2) {
			background: #fff;
			color: #E51E0B;
			border: 1px solid #E51E0B;
		}
	}

	.result {
		margin-left: 48%;
	}
</style>
