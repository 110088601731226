import { render, staticRenderFns } from "./paymentResults.vue?vue&type=template&id=6a3ae066&scoped=true&"
import script from "./paymentResults.vue?vue&type=script&lang=js&"
export * from "./paymentResults.vue?vue&type=script&lang=js&"
import style0 from "./paymentResults.vue?vue&type=style&index=0&id=6a3ae066&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3ae066",
  null
  
)

export default component.exports